import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';
import { 
  MessageSquare,
  BarChart3,
  ChevronRight,
  MessageCircle,
  CircleUser,
  Bot as BotIcon,
  CheckCircle2,
  Search,
  ArrowUpDown,
} from 'lucide-react';
import { cn } from '../lib/utils';
import { Badge } from './ui/badge';

const ModelCard = ({ title, description, icon: Icon, onClick }) => (
  <Card 
    className="relative overflow-hidden transition-all hover:bg-accent/50 cursor-pointer group"
    onClick={onClick}
  >
    <CardContent className="flex flex-col items-center justify-center text-center p-12 space-y-4">
      <div className="rounded-full bg-primary/10 p-4 mb-2">
        <Icon className="h-8 w-8 text-primary" />
      </div>
      <div>
        <h3 className="text-2xl font-medium mb-2">{title}</h3>
        <p className="text-muted-foreground text-base">{description}</p>
      </div>
    </CardContent>
  </Card>
);

const ChatInterface = ({ onBack }) => {
  const [userInput, setUserInput] = useState('');
  const [responses, setResponses] = useState([
    {
      id: 1,
      text: "One fruit you should consider eating for breakfast is a banana. Bananas are a great source of natural sugars, fiber, and essential nutrients like potassium, vitamin C, and vitamin B6. They are also easy to grab and eat on the go, making them a convenient option for a quick and nutritious breakfast."
    },
    {
      id: 2,
      text: "One fruit you should consider eating for breakfast is a banana. Bananas are easy to peel, portable, and provide a good source of energy due to their high carbohydrate content. They are also rich in essential nutrients such as potassium, vitamin C, and dietary fiber. Bananas can be consumed on their own or added to breakfast bowls, smoothies, oatmeal, or yogurt for added flavor and nutrition."
    }
  ]);
  const [selectedResponse, setSelectedResponse] = useState(null);

  return (
    <div className="space-y-8">
      {/* Header with back button */}
      <div className="flex items-center gap-2">
        <Button variant="ghost" size="sm" onClick={onBack}>
          <ChevronRight className="h-4 w-4 rotate-180" />
          Back
        </Button>
        <div>
          <h2 className="text-2xl font-semibold">Chat Fine-tuning</h2>
          <p className="text-sm text-muted-foreground">Train your model through conversation</p>
        </div>
      </div>

      {/* Start a Chat Section */}
      <Card>
        <CardHeader className="flex flex-row items-center gap-4 pb-2">
          <MessageCircle className="h-8 w-8 text-primary" />
          <div>
            <CardTitle>Start a Chat</CardTitle>
            <CardDescription>Write a prompt to start a chat session with the model.</CardDescription>
          </div>
        </CardHeader>
        <CardContent>
          <Textarea
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Write a prompt to start a chat session with the model."
            className="min-h-[100px]"
          />
        </CardContent>
      </Card>

      {/* User Message */}
      {userInput && (
        <div className="flex items-start gap-4 p-4 rounded-lg bg-muted/50">
          <CircleUser className="h-8 w-8 text-muted-foreground" />
          <div className="flex-1">
            <p className="text-sm font-medium mb-1">What is one fruit I should eat for breakfast?</p>
          </div>
        </div>
      )}

      {/* Response Selection Section */}
      {responses.length > 0 && (
        <Card>
          <CardHeader className="flex flex-row items-center gap-4 pb-2">
            <BotIcon className="h-8 w-8 text-primary" />
            <div>
              <CardTitle>Select the best response</CardTitle>
              <CardDescription>When the responses are fully loaded, select the best response from the options.</CardDescription>
            </div>
          </CardHeader>
          <CardContent className="space-y-4">
            {responses.map((response, index) => (
              <div
                key={response.id}
                className={cn(
                  "p-4 rounded-lg border cursor-pointer transition-colors",
                  selectedResponse === response.id
                    ? "border-primary bg-primary/5"
                    : "hover:bg-muted/50"
                )}
                onClick={() => setSelectedResponse(response.id)}
              >
                <div className="flex justify-between items-start mb-2">
                  <h3 className="font-medium">Response {index + 1}</h3>
                  {selectedResponse === response.id && (
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                  )}
                </div>
                <p className="text-sm text-muted-foreground">{response.text}</p>
              </div>
            ))}
            <div className="flex justify-end">
              <Button
                disabled={!selectedResponse}
                onClick={() => console.log('Selected response:', selectedResponse)}
              >
                Continue
                <ChevronRight className="h-4 w-4 ml-2" />
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

const CandidateCard = ({ candidate, isSelected, onSelect }) => (
  <div
    className={cn(
      "p-4 rounded-lg border transition-colors cursor-pointer",
      isSelected ? "border-primary bg-primary/5" : "hover:bg-accent/50"
    )}
    onClick={() => onSelect(candidate.id)}
  >
    <div className="flex justify-between items-start mb-2">
      <div className="flex items-center gap-2">
        <h3 className="font-medium">{candidate.name}</h3>
        <Badge variant="outline">
          Match Score: {(candidate.score * 100).toFixed(0)}%
        </Badge>
      </div>
      {isSelected && (
        <CheckCircle2 className="h-5 w-5 text-primary" />
      )}
    </div>
    <p className="text-muted-foreground">
      {candidate.text}
    </p>
    <div className="flex gap-2 mt-3">
      {candidate.tags.map((tag, tagIndex) => (
        <Badge key={tagIndex} variant="secondary">
          {tag}
        </Badge>
      ))}
    </div>
  </div>
);

const RankingInterface = ({ onBack }) => {
  const [query, setQuery] = useState('');
  const [candidates, setCandidates] = useState([
    {
      id: 1,
      name: "Sarah Chen",
      text: "Senior Software Engineer with 8 years of experience in full-stack development. Expert in React, Node.js, and cloud architecture. Led teams at Google and Amazon, delivering high-impact projects. Strong focus on scalable solutions and mentoring junior developers.",
      score: 0.95,
      tags: ["Senior Level", "Full-stack", "Leadership", "Cloud"]
    },
    {
      id: 2,
      name: "Michael Rodriguez",
      text: "Software Engineer with 5 years of experience in web development. Proficient in JavaScript and React. Contributed to multiple successful projects at startups. Good team player with experience in agile environments.",
      score: 0.82,
      tags: ["Mid Level", "Frontend", "Startup", "Agile"]
    }
  ]);
  const [selectedResponse, setSelectedResponse] = useState(null);

  return (
    <div className="space-y-8">
      <div className="flex items-center gap-2">
        <Button variant="ghost" size="sm" onClick={onBack}>
          <ChevronRight className="h-4 w-4 rotate-180" />
          Back
        </Button>
        <div>
          <h2 className="text-2xl font-medium">Ranking Fine-tuning</h2>
          <p className="text-muted-foreground">Train your ranking model to find the best candidates</p>
        </div>
      </div>

      <Card>
        <CardHeader>
          <div className="flex items-center gap-4">
            <Search className="h-6 w-6 text-primary" />
            <div>
              <CardTitle>Enter Job Search Query</CardTitle>
              <CardDescription>Write a search query to find relevant candidates.</CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <Input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Looking for a senior software engineer with React experience..."
            className="mb-4"
          />
          <div className="flex gap-2">
            <Badge variant="secondary">Software Engineering</Badge>
            <Badge variant="secondary">Senior Level</Badge>
            <Badge variant="secondary">React</Badge>
            <Badge variant="secondary">Full-stack</Badge>
          </div>
        </CardContent>
      </Card>

      {candidates.length > 0 && (
        <Card>
          <CardHeader>
            <div className="flex items-center gap-4">
              <ArrowUpDown className="h-6 w-6 text-primary" />
              <div>
                <CardTitle>Select the best candidate</CardTitle>
                <CardDescription>Choose the candidate that best matches the job requirements.</CardDescription>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {candidates.map((candidate) => (
              <CandidateCard
                key={candidate.id}
                candidate={candidate}
                isSelected={selectedResponse === candidate.id}
                onSelect={setSelectedResponse}
              />
            ))}
            <div className="flex justify-end mt-4">
              <Button
                disabled={!selectedResponse}
                onClick={() => console.log('Selected response:', selectedResponse)}
              >
                Continue
                <ChevronRight className="h-4 w-4 ml-2" />
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

const FineTuning = () => {
  const [selectedModel, setSelectedModel] = useState(null);

  const handleModelSelect = (type) => {
    setSelectedModel(type);
  };

  switch (selectedModel) {
    case 'chat':
      return <ChatInterface onBack={() => setSelectedModel(null)} />;
    case 'ranking':
      return <RankingInterface onBack={() => setSelectedModel(null)} />;
    default:
      return (
        <div className="h-full w-full p-8">
          <div className="space-y-2 mb-8">
            <h1 className="text-4xl font-medium tracking-tight">Fine-tuning</h1>
            <p className="text-lg text-muted-foreground">Select a model type to begin fine-tuning</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-[1200px]">
            <ModelCard
              title="Chat"
              description="Train your chatbot by selecting optimal responses"
              icon={ChatIcon}
              onClick={() => handleModelSelect('chat')}
            />
            <ModelCard
              title="Ranking"
              description="Train your AI search model by ranking results"
              icon={RerankIcon}
              onClick={() => handleModelSelect('ranking')}
            />
          </div>
        </div>
      );
  }
};

// Custom icons to match the design
const ChatIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 3C6.5 3 2 6.5 2 11C2 13.4 3.1 15.5 4.8 17L2.5 21L7 19.5C8.5 20.3 10.2 20.7 12 20.7C17.5 20.7 22 17.2 22 12.7C22 8.2 17.5 3 12 3Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RerankIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3 7H21M3 12H21M3 17H21"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default FineTuning; 