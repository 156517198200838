import React from 'react';
import { Card, CardContent } from './ui/card';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

const data = [
  { date: 'Jan 1', score: 0.72 },
  { date: 'Jan 8', score: 0.75 },
  { date: 'Jan 15', score: 0.79 },
  { date: 'Jan 22', score: 0.81 },
  { date: 'Jan 29', score: 0.84 },
  { date: 'Feb 5', score: 0.87 },
  { date: 'Feb 12', score: 0.89 },
  { date: 'Feb 19', score: 0.91 },
  { date: 'Feb 26', score: 0.93 },
  { date: 'Mar 4', score: 0.97 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-lg border bg-background p-2 shadow-sm">
        <div className="text-sm text-muted-foreground">Model Accuracy</div>
        <div className="text-2xl font-bold">{(payload[0].value * 100).toFixed(1)}%</div>
      </div>
    );
  }
  return null;
};

const Dashboard = ({ companyName }) => {
  return (
    <div className="h-full w-full">
      <div className="px-2 -mt-[60px]">
        <div className="text-2xl font-semibold text-primary">{companyName}</div>
      </div>
      
      <div className="px-2">
        <div className="text-sm text-muted-foreground">Current Model Performance</div>
        <div className="text-3xl font-semibold mb-6">95.0%</div>
      </div>
      
      <div className="px-2">
        <Card className="max-w-[1000px]">
          <CardContent className="p-6">
            <div style={{ width: '100%', height: 400 }}>
              <ResponsiveContainer>
                <LineChart
                  data={data}
                  margin={{ top: 20, right: 30, bottom: 20, left: 20 }}
                >
                  <CartesianGrid 
                    strokeDasharray="3 3" 
                    stroke="hsl(var(--border))"
                    vertical={false}
                  />
                  <XAxis
                    dataKey="date"
                    stroke="hsl(var(--muted-foreground))"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                    dy={10}
                  />
                  <YAxis
                    stroke="hsl(var(--muted-foreground))"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={(value) => `${(value * 100).toFixed(0)}%`}
                    domain={[0.7, 1]}
                    dx={-10}
                  />
                  <Tooltip
                    content={<CustomTooltip />}
                    cursor={{
                      stroke: 'hsl(var(--muted-foreground))',
                      strokeWidth: 1,
                      strokeDasharray: '4 4'
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="score"
                    stroke="hsl(var(--primary))"
                    strokeWidth={3}
                    dot={{
                      stroke: 'hsl(var(--primary))',
                      strokeWidth: 2,
                      r: 4,
                      fill: 'hsl(var(--background))'
                    }}
                    activeDot={{
                      r: 6,
                      stroke: 'hsl(var(--primary))',
                      strokeWidth: 2,
                      fill: 'hsl(var(--background))'
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard; 