import React, { useEffect, useState } from 'react';
import { ClerkProvider, SignIn, SignedIn, SignedOut, useUser, useClerk } from '@clerk/clerk-react';
import AppLayout from './components/AppLayout';
import { createClient } from '@supabase/supabase-js';
import { Loader2 } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';
import { Button } from './components/ui/button';
import { Toaster } from 'sonner';
import { ToastProvider } from "./components/ui/toast"

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

console.log('Supabase Client Initialized:', {
  url: process.env.REACT_APP_SUPABASE_URL?.substring(0, 10) + '...',
  hasAnonKey: !!process.env.REACT_APP_SUPABASE_ANON_KEY
});

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

console.log('Environment Variables Status:', {
  clerkKeyExists: !!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY,
  supabaseUrlExists: !!process.env.REACT_APP_SUPABASE_URL,
  supabaseKeyExists: !!process.env.REACT_APP_SUPABASE_ANON_KEY
});

if (!clerkPubKey) {
  throw new Error('Missing Publishable Key');
}

async function checkAuthorization(email) {
  console.log('Starting authorization check for:', email);
  // Temporarily disable whitelist checks - allow all users
  return true;
}

const AuthWrapper = ({ children }) => {
  const { user, isLoaded } = useUser();
  const { signOut } = useClerk();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [checking, setChecking] = useState(true);

  console.log('AuthWrapper State:', {
    userExists: !!user,
    isLoaded,
    isAuthorized,
    checking
  });

  useEffect(() => {
    const checkAuth = async () => {
      if (!user) {
        console.log('No user found in checkAuth');
        return;
      }

      console.log('Checking authorization for user:', user.emailAddresses[0].emailAddress);
      const email = user.emailAddresses[0].emailAddress;
      const isUserAuthorized = await checkAuthorization(email);
      
      console.log('Authorization result:', isUserAuthorized);
      setIsAuthorized(isUserAuthorized);
      setChecking(false);
    };

    if (isLoaded && user) {
      checkAuth();
    }
  }, [user, isLoaded]);

  if (!isLoaded || checking) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (!isAuthorized && user) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4 space-y-4">
        <Alert variant="destructive">
          <AlertTitle>Access Denied</AlertTitle>
          <AlertDescription>
            Your email is not authorized to access this application.
          </AlertDescription>
        </Alert>
        <Button
          variant="default"
          onClick={() => {
            signOut();
            window.location.href = '/';
          }}
        >
          Return to Sign In
        </Button>
      </div>
    );
  }

  return children;
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Clerk Error:', error);
    console.error('Error Info:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-4">
          <h1>Something went wrong with authentication.</h1>
          <pre>{this.state.error?.message}</pre>
        </div>
      );
    }

    return this.props.children;
  }
}

function App() {
  console.log('App rendering with Clerk key:', clerkPubKey?.substring(0, 10) + '...');
  
  return (
    <ToastProvider>
      <ErrorBoundary>
        <ClerkProvider 
          publishableKey={clerkPubKey}
          options={{
            debug: true,
            clerk: {
              frontendApi: "careful-chow-62.clerk.accounts.dev"
            }
          }}
        >
          <div className="app-container">
            <SignedIn>
              <AuthWrapper>
                <div className="relative">
                  <AppLayout />
                </div>
              </AuthWrapper>
            </SignedIn>
            <SignedOut>
              <div className="container mx-auto py-8 text-center">
                <div className="max-w-sm mx-auto">
                  <SignIn />
                </div>
              </div>
            </SignedOut>
            <Toaster />
          </div>
        </ClerkProvider>
      </ErrorBoundary>
    </ToastProvider>
  );
}

export default App; 