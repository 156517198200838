import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { RadioGroup, RadioGroupItem } from './ui/radio-group';
import { useClerk } from '@clerk/clerk-react';
import { supabase } from '../lib/supabase';
import { Plus, X } from 'lucide-react';
import { useToast } from './ui/use-toast';

const TrainingOption = ({ value, title, description, icon: Icon }) => (
  <Label
    htmlFor={value}
    className="flex flex-col items-center justify-between rounded-lg border-2 border-muted bg-transparent p-4 hover:bg-accent hover:text-accent-foreground [&:has([data-state=checked])]:border-primary cursor-pointer"
  >
    <RadioGroupItem value={value} id={value} className="sr-only" />
    <Icon className="mb-3 h-6 w-6" />
    <h3 className="font-medium">{title}</h3>
    <p className="text-sm text-muted-foreground text-center">{description}</p>
  </Label>
);

const Onboarding = ({ onComplete }) => {
  const [step, setStep] = useState(1);
  const [companyName, setCompanyName] = useState('');
  const [trainingType, setTrainingType] = useState('');
  const [teamMembers, setTeamMembers] = useState(['']);
  const [isLoading, setIsLoading] = useState(false);
  const [existingCompany, setExistingCompany] = useState(null);
  const { user } = useClerk();
  const { toast } = useToast();

  useEffect(() => {
    const checkForInvite = async () => {
      if (!user?.emailAddresses?.[0]?.emailAddress) return;

      const { data: invite } = await supabase
        .from('invites')
        .select('*, user_preferences(*)')
        .eq('email', user.emailAddresses[0].emailAddress)
        .eq('status', 'pending')
        .single();

      if (invite) {
        // Accept the invite
        await supabase
          .from('invites')
          .update({ status: 'accepted' })
          .eq('id', invite.id);

        // Set the user's company
        setExistingCompany(invite.user_preferences);
        onComplete?.();
      }
    };

    checkForInvite();
  }, [user]);

  const sendInvites = async (companyId, emails) => {
    for (const email of emails) {
      if (!email.trim()) continue;

      try {
        // Create invite record
        const { error: inviteError } = await supabase
          .from('invites')
          .insert({
            email: email.trim(),
            company_id: companyId
          });

        if (inviteError) throw inviteError;

        // Send invite email using your email service
        // This is a placeholder - you'll need to implement actual email sending
        console.log(`Sending invite to ${email} for company ${companyId}`);
        
        toast({
          title: "Invite sent",
          description: `Team member invite sent to ${email}`,
        });
      } catch (error) {
        console.error('Error sending invite:', error);
        toast({
          title: "Error sending invite",
          description: `Failed to send invite to ${email}`,
          variant: "destructive",
        });
      }
    }
  };

  const handleContinue = () => {
    if (step === 1 && companyName) {
      setStep(2);
    } else if (step === 2 && trainingType) {
      setStep(3);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleAddMember = () => {
    setTeamMembers([...teamMembers, '']);
  };

  const handleRemoveMember = (index) => {
    const newMembers = teamMembers.filter((_, i) => i !== index);
    setTeamMembers(newMembers);
  };

  const handleMemberChange = (index, value) => {
    const newMembers = [...teamMembers];
    newMembers[index] = value;
    setTeamMembers(newMembers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (step !== 3) {
      handleContinue();
      return;
    }
    
    setIsLoading(true);

    try {
      // Save company preferences
      const { data: preferences, error: prefError } = await supabase
        .from('user_preferences')
        .insert({
          user_id: `user_${user.id}`,
          company_name: companyName,
          training_type: trainingType,
        })
        .select()
        .single();

      if (prefError) throw prefError;

      // Send invites to team members
      const validMembers = teamMembers.filter(email => email.trim() !== '');
      await sendInvites(preferences.id, validMembers);

      onComplete?.();
    } catch (error) {
      console.error('Error saving preferences:', error);
      toast({
        title: "Error",
        description: "Failed to save preferences",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <Label htmlFor="company">What's your company name?</Label>
            <Input
              id="company"
              placeholder="Enter your company name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="w-full"
              required
            />
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <Label>What type of training do you need?</Label>
            <RadioGroup
              value={trainingType}
              onValueChange={setTrainingType}
              className="grid grid-cols-2 gap-4"
            >
              <TrainingOption
                value="chat"
                title="Chat Training"
                description="Train your chatbot by selecting optimal responses"
                icon={ChatIcon}
              />
              <TrainingOption
                value="search"
                title="AI Search"
                description="Train your AI search model by ranking results"
                icon={SearchIcon}
              />
            </RadioGroup>
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            <Label>Add team members</Label>
            <p className="text-sm text-muted-foreground">Invite your team members to collaborate</p>
            {teamMembers.map((member, index) => (
              <div key={index} className="flex gap-2">
                <Input
                  type="email"
                  placeholder="team@company.com"
                  value={member}
                  onChange={(e) => handleMemberChange(index, e.target.value)}
                  className="flex-1"
                />
                {index === teamMembers.length - 1 ? (
                  <Button
                    type="button"
                    variant="outline"
                    size="icon"
                    onClick={handleAddMember}
                  >
                    <Plus className="h-4 w-4" />
                  </Button>
                ) : (
                  <Button
                    type="button"
                    variant="outline"
                    size="icon"
                    onClick={() => handleRemoveMember(index)}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                )}
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  // If user was invited to a company, skip onboarding
  if (existingCompany) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-background/80 backdrop-blur-sm">
      <div className="relative w-full max-w-2xl">
        <Card>
          <CardHeader>
            <CardTitle className="text-2xl">Welcome to Platform</CardTitle>
            <CardDescription>Let's get your workspace set up</CardDescription>
          </CardHeader>
          <form onSubmit={handleSubmit}>
            <CardContent className="space-y-8">
              {renderStepContent()}
            </CardContent>
            <CardFooter className="flex justify-between">
              {step > 1 && (
                <Button
                  type="button"
                  variant="ghost"
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}
              <div className="flex-1 flex justify-end">
                <Button
                  type="submit"
                  disabled={
                    (step === 1 && !companyName) ||
                    (step === 2 && !trainingType) ||
                    isLoading
                  }
                >
                  {step === 3 ? (isLoading ? "Setting up..." : "Get Started") : "Continue"}
                </Button>
              </div>
            </CardFooter>
          </form>
        </Card>
      </div>
    </div>
  );
};

// Custom icons
const ChatIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 3C6.5 3 2 6.5 2 11C2 13.4 3.1 15.5 4.8 17L2.5 21L7 19.5C8.5 20.3 10.2 20.7 12 20.7C17.5 20.7 22 17.2 22 12.7C22 8.2 17.5 3 12 3Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SearchIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Onboarding; 